import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import USA from "../assets/images/18165.png";
import IQD from "../assets/images/27147.png";
const Prices = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      "https://api-exchange.msmar.tech/template/luxorexPrice",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setData(result[0]?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setData("null");

        setLoading(false);
      });
  }, []);
  return (
    <div className=" cont-price ">
      {loading ? (
        <>
          <div className="note">{t("loadingPrice")} </div>
          <div className="loader"></div>
        </>
      ) : (
        <>
          {data !== "null" ? (
            <>
              <div className="sale">
                <ul className=" currency">
                  <li className="name-ex"></li>
                  <li>
                    <img src={USA} alt="luxor prices usa" />
                    USA
                  </li>
                  <li>
                    <img src={IQD} alt="luxor prices usa" />
                    IQD
                  </li>
                </ul>
              </div>
              <div className="sale">
                <ul className="price-ul">
                  <li className="name-ex">{t("buy")}</li>
                  <li>100</li>
                  <li> {data?.Supply}</li>
                </ul>
              </div>
              <div className="Purchase">
                <ul className="price-ul">
                  <li className="name-ex">
                    <div> {t("sale")}</div>
                  </li>
                  <li>100</li>
                  <li>{data?.Demand} </li>
                </ul>
              </div>
            </>
          ) : (
            <div className="noteError">{t("errorPrice")}</div>
          )}{" "}
        </>
      )}
      <div className="note">
        {t("notePrice")}
        <a href="tel:07738829043 "> 07738829043 </a>
      </div>
    </div>
  );
};
export default Prices;
