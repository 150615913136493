import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Logo from "../assets/images/social-svgs/companylogo.svg";
import Home from "../assets/images/social-svgs/home-solid.svg";
import Info from "../assets/images/social-svgs/info-solid.svg";
import Star from "../assets/images/social-svgs/asterisk-solid.svg";
import Users from "../assets/images/social-svgs/users-solid.svg";
import Phone from "../assets/images/social-svgs/phone-alt-solid.svg";
import Header from "../components/header";
import "./mobile/m.scss";
const Navbar = () => {
  const { t } = useTranslation();
  useEffect(() => {
    {
      // Add active class to the current button (highlight it)
      var header = document.getElementById("nav-ul");
      var btns = header.getElementsByClassName("btn");
      for (var i = 0; i < btns.length; i++) {
        btns[i].addEventListener("click", function () {
          var current = document.getElementsByClassName("active");
          current[0].className = current[0].className.replace(" active", "");
          this.className += " active";
        });
      }
    }
  }, []);
  return (
    <body>
      <div className="navall">
        <Header />
        <div className="nav">
          <div className="logo">
            <img src={Logo} />
          </div>
          <ul className="nav-ul" id="nav-ul">
            <li className="btn active">
              <a href="#home">
                <img src={Home} alt="luxor exchange  Home" />
                <div>{t("home")}</div>
              </a>
            </li>
            <li className="btn">
              <a href="#about">
                <img src={Info} alt="luxor exchange  Info" />
                <div> {t("aboutUs")}</div>
              </a>
            </li>
            <li className="btn">
              <a href="#services">
                <img src={Star} alt="luxor exchange  services" />
                <div>{t("services")}</div>
              </a>
            </li>
            <li className="btn">
              <a href="#companies">
                <img src={Users} alt="luxor exchange  companies" />
                <div>{t("companies")}</div>
              </a>
            </li>
            <li className="btn">
              <a href="#contact">
                <img src={Phone} alt="luxor exchange  " />
                <div> {t("callUs")}</div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </body>
  );
};
export default Navbar;
