import { useTranslation } from "react-i18next";

import "./App.scss";
import Navbar from "./components/navbar";
import Cover from "./assets/images/cover.jpg";
import Prices from "./components/prices";
import Body from "./components/body";
import Mobile from "./components/mobile/mobile";
import phone from "./assets/images/phone-alt-solid.png";
import email from "./assets/images/envelope-regular.png";
import facebook from "./assets/images/facebook-f-brands.png";
import whatsapp from "./assets/images/whatsapp-brands.png";
function App() {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  return (
    <>
      <section
        className="Socials text-center no-print  hidden-xs"
        style={{ display: "block" }}
      >
        <ul>
          <li>
            <a href="tel:07738829043">
              <img src={phone} />
            </a>
          </li>
          <li>
            <a href="mailto:info@luxorex.iq">
              <img src={email} />
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/profile.php?id=100095091767537">
              <img src={facebook} />
            </a>
          </li>
          <li>
            <a href="https://wa.me/9647738829043">
              <img src={whatsapp} />
            </a>
          </li>
        </ul>
      </section>
      <Navbar />
      <Mobile />
      <div id="home">
        <section className="home-cover">
          <img src={Cover} alt="luxor exchange  logo" />
        </section>
        <div />
        <Prices />
      </div>
      <Body />
    </>
  );
}

export default App;
