import { useTranslation } from "react-i18next";

import envelope from "../assets/images/social-svgs/envelope-regular.svg";

const Header = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
  };
  return (
    <div className="c-color">
      <div className="header">
        <div className="email">
          <a href="mailto:info@luxorex.iq">info@luxorex.iq</a>

          <img
            src={envelope}
            alt="luxor exchange  email"
            className="envelope"
          />
        </div>
        <ul className="lang">
          <li>
            <button onClick={changeLanguage} value="en" className="btn-lang">
              English
            </button>
          </li>
          <li className="line"></li>
          <li>
            <button onClick={changeLanguage} value="ar" className="btn-lang">
              {t("arabic")}
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Header;
