import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./m.scss";
import Logo from "../../assets/images/social-svgs/companylogo.svg";
import Home from "../../assets/images/social-svgs/home-solid.svg";
import Info from "../../assets/images/social-svgs/info-solid.svg";
import Star from "../../assets/images/social-svgs/asterisk-solid.svg";
import Users from "../../assets/images/social-svgs/users-solid.svg";
import Phone from "../../assets/images/social-svgs/phone-alt-solid.svg";
import { AlignLeftOutlined, AlignRightOutlined } from "@ant-design/icons";
import Header from "../header";
const Mobile = () => {
  const { t } = useTranslation();
  const [isCollapsed, setCollapsed] = useState(false);
  const [active, setActive] = useState(false);
  const toggle = () => {
    setCollapsed(!isCollapsed);
    setActive(!active);
  };
  return (
    <div className="m-nav">
      <div className="container">
        <div className="mobile-nav content">
          <ul className={`active${active}`}>
            <li className="active">
              <img src={Home} alt="luxor exchange  Home" />
              <div>{t("home")}</div>
            </li>
            <li>
              <img src={Info} alt="luxor exchange  Info" />
              <div> {t("aboutUs")}</div>
            </li>
            <li>
              <img src={Star} alt="luxor exchange  services" />
              <div>{t("services")}</div>
            </li>
            <li>
              <img src={Users} alt="luxor exchange  companies" />
              <div>{t("companies")}</div>
            </li>
            <li>
              <img src={Phone} alt="luxor exchange  phone" />
              <div> {t("callUs")}</div>
            </li>
          </ul>
          <div className="container-nav">
            <Header />{" "}
            <div className="trigger-div">
              {React.createElement(
                isCollapsed ? AlignRightOutlined : AlignLeftOutlined,
                {
                  className: "trigger",
                  onClick: toggle,
                }
              )}
              <div className="logo-section">
                <img src={Logo} alt="luxor exchange  logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Mobile;
