import { useTranslation } from "react-i18next";
import Master from "../assets/images/cc-mastercard-brands.png";
import Ex from "../assets/images/exchange-alt-solid.png";
import Visa from "../assets/images/cc-visa-brands.png";
import Qi from "../assets/images/NoPath.png";
import Aps from "../assets/images/NoPath - Copy.png";
import map from "../assets/images/Image 1.png";
const Body = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="body" id="about">
        <div className="head-body">{t("aboutUsTitle")}</div>
        <div className="h-line" />
        <p>{t("aboutUsSec")}</p>
      </div>
      <div className="ser" id="services">
        <div className="head-ser">{t("services")}</div>

        <div className="h-line" />
      </div>
      <div className="container-cards">
        <div className="card-comp ">
          <div className="card-icon">
            <img src={Master} alt="luxor exchange master card" />
          </div>
          <div className="card-title">
            <h4>{t("ser")}</h4> <h2>{t("masterCard")} </h2>
          </div>
          <div className="card-body">{t("masterCardSec")}</div>
        </div>
        <div className="card-comp">
          <div className="card-icon">
            <img src={Ex} alt="luxor exchange  Money" />
          </div>
          <div className="card-title">
            <h4>
              {t("sale")}
              {t("and")} {t("buy")}{" "}
            </h4>
            <h2>{t("currency")}</h2>
          </div>
          <div className="card-body">{t("currencySec")}</div>
        </div>
        <div className="card-comp">
          <div className="card-icon">
            <img src={Visa} alt="luxor Visa card" />
          </div>
          <div className="card-title">
            <h4>{t("ser")}</h4>
            <h2> {t("pos")}</h2>
          </div>
          <div className="card-body">{t("posSec")}</div>
        </div>
      </div>

      <div className="ser" id="companies">
        <div className="head-ser">{t("companies")}</div>
        <div className="h-line" />
      </div>
      <div className="ser">
        <div className="company">
          <img src={Qi} alt="luxor exchange  Money Qi-card" />

          <img src={Aps} alt="luxor exchange  Money APS" />
        </div>
      </div>

      <footer>
        <div className="footerDivContainer">
          <div className="contactUs" id="contact">
            {t("callUs")}
          </div>
          <div className="contactUsLine" />
          <div className="container-contact">
            <ul className="contact">
              <li>
                <b> {t("email")}</b> : info@luxorex.iq
              </li>
              <li>
                <b> {t("phone")}</b> : 07738829043 - 07738829083
              </li>
              <li>
                <b>{t("address")}</b>: {t("addressDet")}
              </li>
            </ul>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d294.77855817978815!2d44.41618532293786!3d33.29165107119427!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x23a6b890258521d!2zMzPCsDE3JzI5LjkiTiA0NMKwMjQnNTguMSJF!5e0!3m2!1sen!2sus!4v1643825534827!5m2!1sen!2sus"
              className="ifram"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
        <div className="footer">{t("copy")}</div>
      </footer>
    </>
  );
};
export default Body;
